import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { authApi } from '@/entities/auth/api/authApi';
import { currencyDetails } from '@/shared/lib/constants/currency';
import { Currency } from '@/shared/types';

interface SettingsState {
  currency: Currency;
}

export const settingsSliceName = 'settings';

const initialState: SettingsState = {
  currency: Currency.USD,
};

const settingsSlice = createSlice({
  name: settingsSliceName,
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(authApi.endpoints.checkUser.matchFulfilled),
      (state, action) => {
        const { currency } = action.payload.data;
        state.currency = currency;
      }
    );
  },
  selectors: {
    selectLocale: (state) => currencyDetails[state.currency].localization,
    selectCurrencySign: (state) => currencyDetails[state.currency].sign,
  },
});

export const { selectLocale, selectCurrencySign } = settingsSlice.selectors;

export default settingsSlice.reducer;
