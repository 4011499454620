import { createSlice, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query';

export const maintenanceSliceName = 'maintenance';

interface State {
  active: boolean;
  retryAfter?: number;
}

const initialState: State = {
  active: false,
  retryAfter: undefined,
};

const maintenanceModeSlice = createSlice({
  name: maintenanceSliceName,
  initialState,
  reducers: {
    toggleMaintenanceMode: (state, action) => {
      state.active = true;
      state.retryAfter = action.payload?.retryAfter;
    },
  },
  selectors: {
    selectIsInMaintenanceMode: (state) => state.active,
    selectMaintenanceRetryAfter: (state) => state.retryAfter,
  },
});

export const { toggleMaintenanceMode } = maintenanceModeSlice.actions;

export const { selectIsInMaintenanceMode, selectMaintenanceRetryAfter } =
  maintenanceModeSlice.selectors;

export default maintenanceModeSlice.reducer;

function isPayloadWithStatus(action: {
  payload: unknown;
}): action is { payload: { status: number } } {
  return (
    typeof action.payload === 'object' &&
    action.payload !== null &&
    'status' in action.payload
  );
}

function isMetaWithBaseQueryMeta(action: { meta: object }): action is {
  meta: {
    baseQueryMeta: FetchBaseQueryMeta;
  };
} {
  return 'baseQueryMeta' in action.meta;
}

export const maintenanceModeMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (
      isRejectedWithValue(action) &&
      isPayloadWithStatus(action) &&
      isMetaWithBaseQueryMeta(action)
    ) {
      if (action.payload.status === 418) {
        const value =
          action.meta.baseQueryMeta.response?.headers.get('Retry-After');
        const retryAfter = value && !Number.isNaN(+value) ? +value : undefined;

        dispatch(toggleMaintenanceMode({ retryAfter }));
      }
    }

    return next(action);
  };
