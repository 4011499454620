import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useCheckUserQuery } from '@/entities/auth/api/authApi';
import { publishers } from '@/shared/lib';
import PageLoader from '@/shared/ui/PageLoader';

const Forward = ({ children }: PropsWithChildren) => {
  const { data, isError } = useCheckUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  if (data) return <Navigate to={publishers.index} />;

  return isError ? children : <PageLoader />;
};

export default Forward;
