import { publisherApi } from '@/shared/api/publisherApi';
import { redirectToMain } from '@/shared/lib';

import type {
  ILoginError,
  ILoginErrorResponse,
  ILoginRequest,
  ILoginSuccessResponse,
  ISocialLoginRequest,
  IUserData,
  RegistrationArgs,
  UpdateArgs,
} from '../model/types';
import { ILoginAsUserRequest } from '../model/types';

export const authApi = publisherApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<ILoginSuccessResponse, ILoginRequest>({
      query: (data: ILoginRequest) => ({
        url: '/user/login',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: ILoginSuccessResponse) => {
        localStorage.setItem('token', response.token);
        return response;
      },
    }),
    socialLogin: build.mutation<ILoginSuccessResponse, ISocialLoginRequest>({
      query: ({ socialite, code }: ISocialLoginRequest) => ({
        url: `/v2/user/${socialite}/login`,
        method: 'POST',
        body: { socialite, code },
      }),
      transformResponse: (response: ILoginSuccessResponse) => {
        localStorage.setItem('token', response.token);
        return response;
      },
      transformErrorResponse: (
        response: ILoginErrorResponse
      ): ILoginError['message'] => response.data.message,
    }),
    loginAsUser: build.mutation<ILoginSuccessResponse, ILoginAsUserRequest>({
      query: (data: ILoginAsUserRequest) => ({
        url: '/api/login-as-user',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: ILoginSuccessResponse) => {
        localStorage.setItem('token', response.token);
        return response;
      },
    }),
    checkUser: build.query<IUserData, void>({
      query: () => ({
        url: '/v2/user/check',
      }),
      providesTags: ['User'],
      transformResponse: (response: IUserData) => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        response.data['is_verified'] = response.data?.info
          ? response.data.info.email_verified_at !== null
          : true;
        return response;
      },
      transformErrorResponse: (response: ILoginErrorResponse) => {
        if (response.status === 401) {
          localStorage.removeItem('token');
        }
        return response;
      },
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: '/user/logout',
        method: 'POST',
      }),
      transformResponse: () => {
        localStorage.removeItem('token');
        redirectToMain();
      },
    }),
    create: build.mutation<ILoginSuccessResponse, RegistrationArgs>({
      query: (data: RegistrationArgs) => ({
        url: '/v2/user/create',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: ILoginSuccessResponse) => {
        localStorage.setItem('token', response.token);
        return response;
      },
    }),
    update: build.mutation<IUserData['data'], Partial<UpdateArgs>>({
      query: (patch) => ({
        url: `/user/update`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['User'],
      transformResponse: (response: IUserData) => response.data,
    }),
  }),
});

export const {
  useLoginAsUserMutation,
  useLoginMutation,
  useSocialLoginMutation,
  useCheckUserQuery,
  useLogoutMutation,
  useCreateMutation,
  useUpdateMutation,
} = authApi;
