import { combineReducers, configureStore } from '@reduxjs/toolkit';

import maintenanceSlice, {
  maintenanceModeMiddleware,
  maintenanceSliceName,
} from '@/features/maintenance-mode/slice';
import settingsSlice, { settingsSliceName } from '@/features/settings/slice';
import { publisherApi } from '@/shared/api/publisherApi';

const rootReducer = combineReducers({
  [publisherApi.reducerPath]: publisherApi.reducer,
  [settingsSliceName]: settingsSlice,
  [maintenanceSliceName]: maintenanceSlice,
} as const);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Skip serializability checks for Blob objects in getPaymentInvoice query
      serializableCheck: {
        ignoredActions: ['publisher/executeQuery/fulfilled'],
        ignoredPaths: [/publisher.queries.getPaymentInvoice.*/],
      },
    }).concat(publisherApi.middleware, maintenanceModeMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type Slices = keyof RootState;

export type AppDispatch = typeof store.dispatch;
