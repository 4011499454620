import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import {
  selectIsInMaintenanceMode,
  selectMaintenanceRetryAfter,
} from '@/features/maintenance-mode/slice';
import NoticeContainer from '@/shared/ui/NoticeContainer';

import { MaintenanceWorksStub } from './MaintenanceWorksStub';

export default function RootLayout() {
  const isInMaintenanceMode = useSelector(selectIsInMaintenanceMode);
  const maintenanceModeRetryAfter = useSelector(selectMaintenanceRetryAfter);

  if (isInMaintenanceMode)
    return <MaintenanceWorksStub retryAfter={maintenanceModeRetryAfter} />;

  return (
    <>
      <NoticeContainer />
      <Outlet />
    </>
  );
}
