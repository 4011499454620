import { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import AuthLayout from '@/app/layout/AuthLayout';
import RootLayout from '@/app/layout/RootLayout';
import { commons, config, publishers } from '@/shared/lib';
import PageLoader from '@/shared/ui/PageLoader';

import RootBoundary from './layout/RootBoundary';

const PublisherLayout = lazy(() => import('./layout/PublisherLayout'));

const Ads = lazy(() => import('@/pages/publisher-ads'));
const Login = lazy(() => import('@/pages/signin'));
const Recovery = lazy(() => import('@/pages/password-recovery'));
const Confirmation = lazy(() => import('@/pages/confirmation'));
const ResendLink = lazy(() => import('@/pages/resend-link'));
const NotVerified = lazy(() => import('@/pages/not-verified'));
const PublisherDashboard = lazy(() => import('@/pages/publisher-dashboard'));
const Payments = lazy(() => import('@/pages/publisher-payments'));
const Profile = lazy(() => import('@/pages/common-profile'));
const Referrals = lazy(() => import('@/pages/publisher-referrals'));
const Registration = lazy(() => import('@/pages/signup'));
const Sites = lazy(() => import('@/pages/publisher-sites'));
const Statistics = lazy(() => import('@/pages/publisher-statistics'));

const LoginAsUser = lazy(() => import('@/pages/login-as-user'));

const publisherRoute = [
  {
    path: publishers.dashboard,
    element: (
      <Suspense fallback={<PageLoader />}>
        <PublisherDashboard />
      </Suspense>
    ),
  },
  {
    path: publishers.ads,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Ads />
      </Suspense>
    ),
  },
  {
    path: publishers.sites,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Sites />
      </Suspense>
    ),
  },
  {
    path: publishers.statistics,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Statistics />
      </Suspense>
    ),
  },
  {
    path: publishers.payments,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Payments />
      </Suspense>
    ),
  },
  {
    path: publishers.referrals,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Referrals />
      </Suspense>
    ),
  },
  {
    path: publishers.profile,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Profile />
      </Suspense>
    ),
  },
  {
    path: publishers.index,
    element: (
      <Navigate to={`${publishers.index}/${config.CABINET_MAIN_PAGE}`} />
    ),
  },
];

const router = createBrowserRouter([
  {
    path: commons.index,
    element: <RootLayout />,
    errorElement: <RootBoundary />,
    children: [
      {
        path: publishers.index,
        element: (
          <Suspense>
            <PublisherLayout />
          </Suspense>
        ),
        children: publisherRoute,
      },
      {
        path: commons.confirmation,
        element: (
          <Suspense fallback={<PageLoader />}>
            <Confirmation />
          </Suspense>
        ),
      },
      {
        path: commons['resend-email'],
        element: (
          <Suspense fallback={<PageLoader />}>
            <ResendLink />
          </Suspense>
        ),
      },
      {
        path: commons['not-verified'],
        element: (
          <Suspense fallback={<PageLoader />}>
            <NotVerified />
          </Suspense>
        ),
      },
      {
        element: <AuthLayout />,
        children: [
          {
            path: commons.signin,
            element: (
              <Suspense fallback={<PageLoader />}>
                <Login />
              </Suspense>
            ),
          },
          {
            path: commons.signup,
            element: (
              <Suspense fallback={<PageLoader />}>
                <Registration />
              </Suspense>
            ),
          },
          {
            path: commons['password-recovery'],
            element: (
              <Suspense fallback={<PageLoader />}>
                <Recovery />
              </Suspense>
            ),
          },
          {
            path: commons.index,
            element: <Navigate to={commons.signin} />,
          },
        ],
      },
      {
        path: commons['login-as-user'],
        element: (
          <Suspense fallback={<PageLoader />}>
            <LoginAsUser />
          </Suspense>
        ),
      },
    ],
  },
]);

export default router;
