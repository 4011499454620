import { formatDuration, intervalToDuration } from 'date-fns';
import { useEffect } from 'react';

const FIVE_MINUTES = 5 * 60; // in seconds
const TIMEOUT_FIVE_MINUTES = 5 * 60 * 1000;

type Props = {
  retryAfter?: number;
};

export function MaintenanceWorksStub({ retryAfter = FIVE_MINUTES }: Props) {
  const duration = intervalToDuration({
    start: 0,
    end: retryAfter * 1000,
  });

  const formattedDuration = formatDuration(duration, {
    format: ['hours', 'minutes'],
    delimiter: ' and ',
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, TIMEOUT_FIVE_MINUTES);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex h-full w-full justify-center bg-[url(/background.svg)] bg-center bg-no-repeat">
      <div className="mx-4 flex h-fit translate-y-32 flex-col items-center rounded-3xl border border-white border-opacity-40 bg-[#FFFFFF66] bg-opacity-30 p-10 shadow-md backdrop-blur-md">
        <div className="mb-4 text-center text-4xl font-semibold md:text-6xl">
          Maintenance works
        </div>
        <div className="text-center text-2xl">
          Thanks for your patience!
          <br />
          Try accessing the page again in{' '}
          <span className="font-semibold">~{formattedDuration}</span>
        </div>
      </div>
    </div>
  );
}
